'use client'
import { useEffect, useState, useRef, useMemo } from 'react'
import type { RefObject } from 'react'

interface IntersectionOptions extends IntersectionObserverInit {
  threshold?: number | number[]
  rootMargin?: string
  triggerOnce?: boolean
  initialInView?: boolean
  skip?: boolean
}

type InViewHookResponse = {
  ref: RefObject<any> | ((node?: Element | null) => void)
  inView: boolean
  entry?: IntersectionObserverEntry
}

export const useInView = (options: IntersectionOptions = {}): InViewHookResponse  => {
  const { threshold = 0, rootMargin = '0px', triggerOnce = false, initialInView = false, skip = false } = options
  const ref = useRef<Element | null>(null)
  const [inView, setInView] = useState<boolean>(initialInView || false)
  const [entry, setEntry] = useState<IntersectionObserverEntry | undefined>(undefined)

  const observerOptions = useMemo(() => ({
    threshold,
    rootMargin,
  }), [threshold, rootMargin])

  useEffect(() => {
    if (skip || !ref) return

    let observer: IntersectionObserver | undefined

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [firstEntry] = entries

      setInView(firstEntry.isIntersecting)
      setEntry(firstEntry)
      if (observer && triggerOnce && firstEntry.isIntersecting) observer.disconnect()
    }

    if (typeof IntersectionObserver !== 'undefined') {
      observer = new IntersectionObserver(handleIntersection, observerOptions)

      const currentRef = ref.current
      if (currentRef) observer.observe(currentRef)
    } else {
      // Fallback behavior when IntersectionObserver is not available
      console.warn('IntersectionObserver is not supported in this browser. Please consider using a polyfill.')
    }

    return () => { if (observer) observer.disconnect() }
  }, [observerOptions, triggerOnce, skip])

  return { ref, inView, entry }
}
